<template>
  <comercial-tpl>
    <b-card
      title=""
      class=" mx-auto"
      
      align-v="center">    
    <b-table-simple hover small responsive class="text-left">
    <colgroup><col><col><col></colgroup>
    <colgroup><col><col></colgroup>
    <colgroup><col></colgroup>      
    <b-thead head-variant="dark">
      <b-tr>
        <b-th colspan="3">Nome</b-th>
        <b-th colspan="2">Indicado por</b-th>
        <b-th colspan="1">Data</b-th>
      </b-tr>
    </b-thead>

    <b-tbody>
      <b-tr v-for="item in sql" :key="item.id">
       
        <b-td><router-link :to="'/indique/ver/'+item.id">{{item.name}}</router-link></b-td>
        <b-td></b-td>
        <b-td></b-td>
        <b-td>{{item.nameresp}}</b-td>
        <b-td></b-td>
        
        <b-td>
          <b-td>{{item.created_at}}</b-td>
        </b-td>
        
        
      </b-tr>

    </b-tbody>

    </b-table-simple>

      <div class="card-footer">
          <pagination :data="pg" @pagination-change-page="getResults"></pagination>
      </div>

    </b-card>
  </comercial-tpl>
</template>

<script>
// @ is an alias to /src

import ComercialTpl from '@/tpls/ComercialTpl.vue'

export default {
  name: 'ListarIndicados',
  data() {
    return {
      sql:{},
        
    }
  },

  mounted() {
    // Fetch initial results
    this.getResults();
  },


  components: {
    ComercialTpl,
  },
/*
  created(){
    
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'newsletter/listar/',
      {"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})
        .then(response => {
          //
          if(response.data.status){
            console.log(response);
            this.news = response.data.news.data;
            //this.pg = response.data.news;
          }
        })
          
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })  

      }
    },
    */
  
  methods:{
    getResults(page = 1) {
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'indique/listar?page=' + page)
      //{"headers": {"authorization": "Bearer "+this.$store.getters.getToken}})      
      
        .then(response => {
          if(response.data.status){
          console.log(response);
          this.sql = response.data.sql.data;
          this.pg = response.data.sql;
          }
        })
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })          
      }   
    }

  },
}
  </script>

